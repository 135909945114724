import { useEffect, useState } from 'react';
import "./styles/Header.css";

const HeaderComponent = (props) => {
  const [animatedText, setAnimatedText] = useState("");

  useEffect(() => {
    // Moved the function inside the useEffect callback
    const generateAnimatedText = () => {
      const text = props.title;
      let container = "";
      let i = 0;

      const intervalId = setInterval(() => {
          container += text.charAt(i);
          setAnimatedText(container);
          i++;
      }, 100);

      // Return the interval ID so we can clear it when the component unmounts
      return intervalId;
    };

    const intervalId = generateAnimatedText();

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [props.title]);

  return (
    <div className="basicTitleBox">
      <div className='noiseBlock'>
        <h1 className="mainTitle">{animatedText}</h1>
      </div>
    </div>
  );
};

export default HeaderComponent;

/**
 * @author Michal Tvrdy
 */

import './App.css';

import { useState } from 'react';
import MainMenuComponent from './MainMenuComponent';
import HeaderComponent from './HeaderComponent';

import BlogPageComponent from "./BlogPageComponent";
import HomepageComponent from "./HomepageComponent";
import CreditsPageComponent from "./CreditsPageComponent";
import MerchPageComponent from "./MerchPageComponent";
import GamesPageComponent from './GamesPageComponent';

const PageContentComponent = (props) => {

  let currentContent;
  let currentTitle = "INCORRECT GAMES";

  const [currentPage, setNewPage] = useState(props.selectedPage);

  const handlePageChange = (value) => {
    return setNewPage(value);
  }

  if(currentPage === "home") {
    currentContent = <HomepageComponent pageRedirect={handlePageChange}/>;
  } else if (currentPage === "blog") {
    currentTitle = "IG BLOG";
    currentContent = <BlogPageComponent />;
  } else if (currentPage === "merch") {
    currentTitle = "IG MERCH";
    currentContent = <MerchPageComponent />;
  } else if (currentPage === "games") {
    currentTitle = "IG GAMES";
    currentContent = <GamesPageComponent />;
  } else if (currentPage === "credits") {
    currentTitle = "ABOUT IG";
    currentContent = <CreditsPageComponent />;
  } else {
    currentContent = <HomepageComponent pageRedirect={handlePageChange}/>;
  }

  return(
    <div>
      <MainMenuComponent onMenuChange={handlePageChange}/>
      <center><HeaderComponent title={currentTitle}></HeaderComponent></center>
      <center>{currentContent}</center>
    </div>
    )
}

export default PageContentComponent;
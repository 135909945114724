/**
 * @author Michal Tvrdy
 */

import './styles/BlogPost.css';

import sampleImage from './images/megahate_cover2.png';

import {useState} from "react";

const BlogPostComponent = ((props) => {

  const [contentHiden, setContentVisible] = useState(false);

  const displayRestOfTheContent = () => {
    contentHiden ? setContentVisible(false) : setContentVisible(true);
  };

    return(
    <div>
      <div className="blogBlock">
          <h2>{props.items.title}</h2>
          <div className="blogBlockParaHolder">
            <p className="blogBlockPara" align="left">{props.items.appendix}</p>
            <div>
              <img alt="Tommy Kentus" src={sampleImage}></img>
            </div>
          </div>
            {contentHiden && (
            <div>
              {props.items.text}
              <div className="blogPostGalleryBlock">
                {props.items.images.map((imageUrl) => (<img alt="imageText" src={imageUrl}></img>))}
                </div>
                <div>
                  {props.items.paragraphs.map((paragraph) => (<p className="blogBlockPara" align="left">{paragraph}</p>))}
                </div>
            </div>
            )}
          <button className="readMoreButton" onClick={displayRestOfTheContent}>{contentHiden ? "Show less" : "Show more"}</button>
      </div>
      </div>
    )
});

export default BlogPostComponent;
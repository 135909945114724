/**
 * @author Michal Tvrdy
 */

import './App.css';
import AuthorsComponent from './AuthorsComponent';
import BlogFeedComponent from "./BlogFeedComponent";
import GameAnnouncementComponent from './GameAnnouncementComponent';
import OurGamesComponent from './OurGamesComponent';
import SupportUsComponent from './SupportUsComponent';

const HomepageComponent = ({pageRedirect}) => {

  const handleComponentPageRedirect = (value) => {
    pageRedirect(value);
  }

  return(<div>
      <center><GameAnnouncementComponent /></center>
      <center><SupportUsComponent /></center>
      <center><OurGamesComponent pageRedirect={handleComponentPageRedirect} /></center>
      <center><AuthorsComponent /></center>
      <center><BlogFeedComponent /></center>
    </div>
  )
}

export default HomepageComponent;
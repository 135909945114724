/**
 * @author Michal Tvrdy
 */

import './App.css';

import backgroundImage from './images/dalle_background.png';

import PageContentComponent from './PageContentComponent';
import FooterComponent from './FooterComponent';
// import TestComponent1 from './testComponents_passingPropsThrough/TestComponent1';

function App() {

  return (
    <div style={{ backgroundImage: `url(${backgroundImage})` }}>
      <center><PageContentComponent></PageContentComponent></center>
      <center><FooterComponent></FooterComponent></center>
    </div>
  );
}

export default App;
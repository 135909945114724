/**
 * @author Michal Tvrdy
 */

import './styles/GamePage.css';
import megahateImage from './images/megahate_ico.png';
import spacezImage from './images/spacez.png';
import tokioImage from './images/tokio.png';
import downloadIosImage from './images/download_ios_small.png';
import downloadAndroidImage from './images/download_small.png';

// Megahate Images


function GamesPageComponent() {
    return(
      <div className="ourGamesMainBlock">
        <div className="ourGamesMainBlockTitle"><h1>Our Games</h1></div>

        <div className="gameAnnouncementBlock">
          <div className="gameAnnouncementInblock">
            <center><div><h1 className="gameAnnouncementTitle">MEGAHATE</h1></div></center>
            <img alt="Megahate" src={megahateImage}></img>
            <h3>
              Our latest piece! Become Hateman and help people of Lubna village to destroy the evil.
            </h3>
            <p className="gameAnnouncementBlogBlockPara" align="left">
              Released in 2023 this is our latest piece of work. Fast-paced 2D game that will kick you back into 90s! This project really makes us happy so we plan to expand current content and try another platforms like PC!
            </p>
            <div className="gameVideoBlockWrapperBlock">
            <iframe className="gameAnnouncementVideoBlock" src="https://www.youtube.com/embed/9MneXZvPER4" title="Megahate Official App Promo Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className="gameVideoBlockWrapperBlock">
            <iframe className="gameAnnouncementVideoBlock" src="https://www.youtube.com/embed/D7dGAU9KPdo?start=1" title="This is MEGAHATE Promo video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <p>Download on:</p>
            <div className="gameAnnouncementDownloadBlock">
              <a href="https://apps.apple.com/cz/app/megahate/id6445963304"><img alt="Download IOS" src={downloadIosImage}></img></a>
              <a href="https://play.google.com/store/apps/details?id=com.IncorrectGames.Megahate"><img alt="Download Android" src={downloadAndroidImage}></img></a>
            </div>
          </div>
        </div>

        <div className="gamePageComponentDivider"></div>

        <div className="gameAnnouncementBlockSpaceZ">
          <div className="gameAnnouncementInblock">
            <center><div><h1 className="gameAnnouncementTitle">SpaceZ</h1></div></center>
            <img alt="SpaceZ" src={spacezImage}></img>
            <p className="gameAnnouncementBlogBlockPara" align="left">
              Join Zman leave the Earth and reach Mars! Tap the display repeatly to trigger left or right thrust and fly! Thanks to online scoreboard you can compete with your friends!
            </p>
            <div className="gameVideoBlockWrapperBlock">
              <iframe className="gameAnnouncementVideoBlock" src="https://www.youtube.com/embed/fUgB2qpDXPE" title="Megahate Promo Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <p>Download on:</p>
            <div className="gameAnnouncementDownloadBlock">
              <a href="https://play.google.com/store/apps/details?id=com.Incorrectgames.SpaceZ&hl=cs&gl=US"><img alt="Download Android" src={downloadAndroidImage}></img></a>
            </div>
          </div>
        </div>

        <div className="gamePageComponentDivider"></div>

        <div className="gameAnnouncementBlockTokio">
          <div className="gameAnnouncementInblock">
            <center><div><h1 className="gameAnnouncementTitle">Tokio Oklahoma</h1></div></center>
            <img alt="Tokio Oklahoma" src={tokioImage}></img>
            <p className="gameAnnouncementBlogBlockPara" align="left">
              Help famous Tokio Oklahoma help to get home. To succeed you must avoid everything what he hates! Tap on display to fly and enjoy journey of Tokio's life!
            </p>
            <div className="gameVideoBlockWrapperBlock">
              <iframe className="gameAnnouncementVideoBlock" src="https://www.youtube.com/embed/vYrQrlkHF_k" title="Megahate Promo Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <p>Download on:</p>
            <div className="gameAnnouncementDownloadBlock">
              <a href="https://play.google.com/store/apps/details?id=com.Incorrectgames.Tokiooklahoma"><img alt="Download Android" src={downloadAndroidImage}></img></a>
            </div>
          </div>
        </div>

        <div className="gamePageComponentDivider"></div>

      </div>
    )
}

export default GamesPageComponent;
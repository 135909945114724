/**
 * @module SupportUsComponent.js
 * @author Michal Tvrdy
 */

import './styles/SupportUs.css';

import megahateCoin from './images/hatecoin.gif';

function SupportUsComponent() {
    return(
      <div className="supportUsContentBlock">
        <center><h1 className="supportUsTitle">Buy Soundtrack & Support us!</h1></center>
        <img alt="Rotating Pixelated Hatecoin" src={megahateCoin}></img>
          <p className="supportUsParagraph">Wanna support us or just can't get enough of Mr Ecstasy music? Check our BandCamp! Your contribution will be used to get this piece of sh*t to Steam with couch coop!!!</p>

          <div className="megahateSoundtrackBlock">
            <iframe title="Megahate OST" className="megahateSoundtrackInframe" src="https://bandcamp.com/EmbeddedPlayer/album=2739005024/size=large/bgcol=ffffff/linkcol=0687f5/artwork=small/transparent=true/" seamless><a href="https://radekchloupek.bandcamp.com/album/megahate-ost">Megahate(OST) by Radek Chloupek</a></iframe>
          </div>
          <div className="supportUsButtonBlock">
            <a href="https://radekchloupek.bandcamp.com/album/megahate-ost?action=buy&from=embed" className="supportUsButton">I'm buyin it!</a>
            </div>
      </div>
    )
}

export default SupportUsComponent;
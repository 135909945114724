/**
 * @author Michal Tvrdy
 */

import "./styles/BlogFeed.css";

import BlogPostComponent from "./BlogPostComponent";

function BlogFeedComponent() {

  const BLOG_POSTS = [
    {
      title: "Megahate - save the date!",
      appendix: "Megahate has been already submitted for review and you expect Megahate on hell thursday 1/6/2023. ",
      text: "Check out blog for latest news and don't forget to visit our new merch site! In case you like anything, ping us at e-mail or simply by the contacting form down below! You can also support us & tease yourself with buying official Megahate soundtrack! Enjoy the teaser and stay tuned!",
      image: "",
      paragraphs: [],
      images: [
        "https://incorrectgames.com/images/megahate/megahate_workingon3.png"
      ]
    }
  ];

    return(
      <div className="blogfeedComponentMainBlock">
      <h1 className="blogfeedComponentTitle">Blog Feed</h1>
      {BLOG_POSTS.map((post) => (
        <BlogPostComponent items={post}/>
      ))};
        <button className="blogfeedButton">See more</button>
      </div>
    )
}

export default BlogFeedComponent;
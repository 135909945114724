import './styles/MainMenu.css';

const MainMenuComponent = ({onMenuChange}) => {
    
    const handleButtonClick = (value) => {
      onMenuChange(value);
    };

    return(<div className="mainMenu">
        <button className="buttonMenu"  onClick={() => handleButtonClick("home")}>home</button>
        <button className="buttonMenu"  onClick={() => handleButtonClick("blog")}>blog</button>
        <button className="buttonMenu"  onClick={() => handleButtonClick("games")}>games</button>
        <button className="buttonMenu"  onClick={() => handleButtonClick("merch")}>merch</button>
        <button className="buttonMenu"  onClick={() => handleButtonClick("credits")}>creditz</button>
      </div>
    )
}

export default MainMenuComponent;
/**
 * @author Michal Tvrdy
 */

import "./styles/Credits.css";
import radekImage from './images/authors/rad.png';
import tommyKentusImage from './images/authors/tk.png';
import igimage from "./images/authors/iglogo.png"

import igLogo from "./images/social/iglogo.png";
import fbLogo from "./images/social/facebooklogo.png";
import tiktokLogo from "./images/social/tiktoklogo.png";

import { useState } from "react";

function AuthorsComponent() {

    const [contactHidden, showContactInfo] = useState(false);

    const showContactInformation = () => {
      contactHidden ? showContactInfo(false) : showContactInfo(true);
    }

    return(
      <div className="authorsComponentMainBlock">
      <h1 className="authorsComponentBlockTitle">Authors</h1>
        <div className="authorsComponentBlock">
          <div className="authorSectionComponentBlock">
            <img alt="Tommy Kentus" src={tommyKentusImage}></img>
            <h2 className="authorsComponentTitle" align="center">
              Michal Tvrdy
          </h2>
          <p className="authorsComponentPara">Gives deus ex to machina of our games</p>
          </div>
          <div className="authorSectionComponentBlock">
            <img alt="Radek Chloupek" src={radekImage}></img>
            <h2 className="authorsComponentTitle" align="center">
              Radek Chloupek
          </h2>
          <p className="authorsComponentPara">Everything you can hear and feel in our games is done by him!</p>
          </div>
        </div>

        <div><h1 className="authorsComponentBlockTitle">Want updates? Subscribe!</h1></div>

        <div className="socialMediaBlock">
          <div className="socialMediaItemBlock">
            <a className="socialMediaLink" href="https://www.instagram.com/incorrectgames"><img className="socialMediaImage" alt="Instagram Page Link" src={igLogo}></img></a>
          </div>
          <div className="socialMediaItemBlock">
            <a className="socialMediaLink" href="https://www.facebook.com/incorrectgames"><img className="socialMediaImage" alt="Facebook Page Link" src={fbLogo}></img></a>
          </div>
          <div className="socialMediaItemBlock">
            <a className="socialMediaLink" href="https://www.tiktok.com/@incorrectgames"><img className="socialMediaImage" alt="TikTok Page Link" src={tiktokLogo}></img></a>
          </div>
        </div>

        <div><h1 className="authorsComponentBlockTitle">Want something more?</h1></div>

        {contactHidden && <div className="authorsContactComponentBlock">
            <h2 className="authorsComponentTitle">Incorrect Games</h2>
            <img alt="Incorrect Games" src={igimage}></img>
            <h2>gamesincorrect@gmail.com</h2>
          </div>
        }

        {!contactHidden && <button className="contactUsButton" onClick={showContactInformation}>Contact us!</button>}
      </div>
    )
}

export default AuthorsComponent;
import './styles/Merch.css';

function MerchPageComponent() {
    return(<div className="underConstructionBlock">
      <div className="underConstructionInblock">
        <center><div><h1 className="underConstructionTitle">PAGE IS UNDER CONSTRUCTION</h1></div></center>
      </div>
      </div>
    )
}

export default MerchPageComponent;
/**
 * @author Michal Tvrdy
 */
import "./styles/GameAnnouncement.css";

import downloadIosImage from './images/download_ios_small.png';
import downloadAndroidImage from './images/download_small.png';

function GameAnnouncementComponent() {
    return(<div className="gameAnnouncementBlock">
      <div className="gameAnnouncementInblock">
        <center><div><h1 className="gameAnnouncementTitle">MEGAHATE IS OUT!</h1></div></center>
        <p className="gameAnnouncementBlogBlockPara" align="left">
            We are more than happy to announce that MEGAHATE is finally out! Find out who are the mystical Hatemans and what happened in Lubna village!
        </p>
        <div className="videoWrapper">
        <iframe className="videoWrapper" src="https://www.youtube.com/embed/D7dGAU9KPdo?start=1" title="Megahate Promo Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <h2>Download MEGAHATE game on:</h2>
      <div className="gameAnnouncementDownloadBlock">
        <a href="https://apps.apple.com/cz/app/megahate/id6445963304" className="downloadButton"><img alt="Download IOS" src={downloadIosImage}></img></a>
        <a href="https://play.google.com/store/apps/details?id=com.IncorrectGames.Megahate" className="downloadButton"><img alt="Download Android" src={downloadAndroidImage}></img></a>
      </div>
      </div>
      </div>
    )
}

export default GameAnnouncementComponent;
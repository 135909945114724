/**
 * @author Michal Tvrdy
 */

import './App.css';

function FooterComponent() {
    return(<div className="footerBlock">
        <p>All rights reserved 2023 INCORRECT GAMES</p>
      </div>
    )
}

export default FooterComponent;
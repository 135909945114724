/**
 * @author Michal Tvrdy
 */

import './App.css';
import megahateImage from './images/megahate_ico.png';
import spacezImage from './images/spacez.png';
import tokioImage from './images/tokio.png';

const OurGamesComponent = ({pageRedirect}) => {

  const handleButtonClick = () => {
    // Define the props/data you want to pass
    // const data = "games";
    // Call a function from the parent component that handles the page change and pass the data as an argument
    // Assuming the function in the parent component is called `handlePageChange`
    // You can replace `data` with the actual props you want to pass
    // handlePageChange(data);
    pageRedirect("games");
  };

  return(
    <div>
     <h1 className="ourGamesMainBlockTitle">Check out our games!</h1>
      <div className="ourGamesBlock2">
          <div className="ourGamesGameBlock">
            <img alt="Megahate" src={megahateImage}></img>
            <h3 className="ourGamesGameTitle">MEGAHATE</h3>
            <p>Megahate is finally out and available for iOS and Android! Help people of Lubná village in their fight against injustice! Experience, what does it mean to be a real Hateman!</p>
          </div>
          <div className="ourGamesGameBlock">
            <img alt="SpaceZ" src={spacezImage}></img>
            <h3 className="ourGamesGameTitle">SpaceZ</h3>
            <p>SpaceZ is finally released and available for Android! Join Zman leave the Earth and reach Mars! Tap the display repeatly to trigger left or right thrust and fly! Thanks to online scoreboard you can compete with your friends!</p>
          </div>
          <div className="ourGamesGameBlock">
            <img alt="Tommy Kentus" src={tokioImage}></img>
            <h3 className="ourGamesGameTitle">Tokio Oklahoma</h3>
            <p>Help famous Tokio Oklahoma help to get home. To succeed you must avoid everything what he hates! Tap on display to fly and enjoy journey of Tokio's life!</p>
          </div>
      </div>
      <button className="findOutMoreButton" onClick={handleButtonClick}>Find out more!</button>
    </div>
  )

  /*
  return(
    <div className="ourGamesMainBlock">
      <div className="ourGamesMainBlockTitle"><h1>Check out our games!</h1></div>
      <div className="ourGamesBlock">
          <div className="ourGamesGameBlock">
            <img alt="Megahate" src={megahateImage}></img>
            <h3 className="ourGamesGameTitle">MEGAHATE</h3>
            <p>Megahate is finally out and available for iOS and Android! Help people of Lubná village in their fight against injustice! Experience, what does it mean to be a real Hateman!</p>
          </div>
          <div className="ourGamesGameBlock">
            <img alt="SpaceZ" src={spacezImage}></img>
            <h3 className="ourGamesGameTitle">SpaceZ</h3>
            <p>SpaceZ is finally released and available for Android! Join Zman leave the Earth and reach Mars! Tap the display repeatly to trigger left or right thrust and fly! Thanks to online scoreboard you can compete with your friends!</p>
          </div>
          <div className="ourGamesGameBlock">
            <img alt="Tommy Kentus" src={tokioImage}></img>
            <h3 className="ourGamesGameTitle">Tokio Oklahoma</h3>
            <p>Help famous Tokio Oklahoma help to get home. To succeed you must avoid everything what he hates! Tap on display to fly and enjoy journey of Tokio's life!</p>
          </div>
      </div>
      <button className="findOutMoreButton" onClick={handleButtonClick}>Find out more!</button>
    </div>
  )

  */

}

export default OurGamesComponent;
/**
 * @author Michal Tvrdy
 */

import './styles/Credits.css';

import AuthorsComponent from './AuthorsComponent';


function CreditsPageComponent() {
    return(<div>
        <center>
          <div className='creditsPageComponentBlock'>
        <div className="authorsComponentBlock">
          <div className="authorSectionComponentBlock">
              <h2 className="authorsComponentTitle" align="center">
                About Us
              </h2>
              <p className="authorsComponentPara">We are a small independent studio based in Czech Republic. We have started as a group-of-three studio in 2018. So far we have already produced 3 games and works on another.</p>
          </div>
        </div>
        </div>
        </center>
        <center><AuthorsComponent /></center>
      </div>
    )
}

export default CreditsPageComponent;